import React from 'react'
import home_about_secondary_ from '../../Image/home-about-secondary-img.png'
import { Link } from 'react-router-dom';

export default function About(props) {
    const { aboutRef } = props;
    return (
        <>
            <div className="container" ref={aboutRef}>
                <div className="section_home-about !sm:pt-[8em] !py-[4.75em]">
                    <div className="home-about_img-c">
                        <div className="image-container_absolute">
                            <img
                                src="../uploads-ssl.webflow.com/62bc63a42ce68cd398f4eb1d/62cd89dfe866573eca16388a_home-about_building.png"
                                loading="lazy" data-w-id="dd00d3a1-fd54-0fc1-81a7-20f60f7a12da" alt=""
                                srcSet="https://uploads-ssl.webflow.com/62bc63a42ce68cd398f4eb1d/62cd89dfe866573eca16388a_home-about_building-p-500.png 500w, https://uploads-ssl.webflow.com/62bc63a42ce68cd398f4eb1d/62cd89dfe866573eca16388a_home-about_building.png 790w"
                                sizes="(max-width: 991px) 88vw, 40vw" className="image_fit-cover is--align-top" />
                            {/* <img
                                src={home_about_secondary_}
                                loading="lazy" alt="" className="home-about_secondary-img" /> */}
                            <img
                                src="https://uploads-ssl.webflow.com/62bc63a42ce68cd398f4eb1d/62c08daf0babd5b0dbbd6d6d_home-about-line.svg"
                                loading="lazy" alt="" className="home-about_line" />
                        </div>
                    </div>
                    <div className="home-about_info-pattern-c">
                        <img
                            src="https://uploads-ssl.webflow.com/62bc63a42ce68cd398f4eb1d/62c4407e2f0c085ea937d430_home-about_pattern.svg"
                            loading="lazy" alt="" className="home-about_pattern" />
                        <div className="home-about_info-c">
                            <h2 className="heading-large is--white">About Capital Intrinsic</h2>
                            <div className="home-about_p-c">
                                <p className="body-large">
                                    At Capital Intrinsic Real Estate Investment, we are dedicated to unlocking and maximizing the value of real estate assets.
                                    <br /><br />
                                    Our meticulous approach combines value investing principles with keen market foresight. As we navigate through surging urbanization, our strategy, deeply ingrained in exhaustive market analysis and authentic local acumen, is devised to yield robust returns for investors while simultaneously elevating the living standards of our residents.
                                </p>
                            </div>
                            <Link to="Invest_With_Us">
                                <div className="button bg-[#fa6e68] !sm:px-[30px] !px-[20px] !sm:py-[0.95em] !py-[0.5em] group hover:bg-transparent border border-[#fa6e68] rounded-lg  transition-all duration-500 ">
                                    <div className="button_text-before is--navy group-hover:text-white transition-all duration-500">Invest with us</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </>

    )
}
