import React from 'react'
import { Link } from 'react-router-dom'
import twitter from '../../Image/twitter svg.svg'

export default function Footer() {
    return (
        <div>



            <div className="footer_wrap relative z-30">
                <div className="container">
                    <div className="section_footer">
                        <div className="footer_middle-row">
                            <Link to="/" data-w-id="315c2a4f-6af8-1b69-be4f-82c281d0b3d6" href="" aria-current="page" className="nav_logo-link w-inline-block w--current text-[32px] leading-none !h-auto">
                                 Capital Intrinsic
                            </Link>
                            <div className="social_c">
                                <a href="https://www.instagram.com/capitalintrinsic"
                                    className="social-link w-inline-block">
                                    <img src="https://uploads-ssl.webflow.com/62bc63a42ce68cd398f4eb1d/62c5da96959485c56789dffb_footer_social-ig.svg" loading="lazy" alt="" className="footer_social-icon" /></a>
                                <a href="https://www.facebook.com/capitalintrinsic" className="social-link w-inline-block">
                                    <img src="https://uploads-ssl.webflow.com/62bc63a42ce68cd398f4eb1d/62c5da953f28cc72fe3c4876_footer_social-fb.svg" loading="lazy" alt="" className="footer_social-icon" /></a>
                                <a href="https://www.linkedin.com/company/capitalintrinsic" className="social-link w-inline-block">
                                    <img src="https://uploads-ssl.webflow.com/62bc63a42ce68cd398f4eb1d/62c5da95796ddff7a3e81f6b_footer_social-linkedin.svg" loading="lazy" alt="linkedin" className="footer_social-icon" /></a>
                                <a href="https://www.twitter.com/capintrinsic" className="social-link w-inline-block">
                                    {/* <svg width="16" height="17" viewBox="0 0 16 17" className=' footer_social-icon' fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <g clipPath="url(#clip0_240_136)">
                                            <path d="M9.31264 7.26362L15.1379 0.492188H13.7575L8.69942 6.37172L4.65953 0.492188H0L6.10911 9.38309L0 16.484H1.38049L6.72197 10.275L10.9884 16.484H15.6479L9.3123 7.26362H9.31264ZM7.42187 9.46142L6.8029 8.57609L1.87789 1.5314H3.99824L7.97277 7.21669L8.59175 8.10202L13.7582 15.492H11.6378L7.42187 9.46176V9.46142Z" fill="#FFF"></path>
                                        </g>
                                    </svg> */}
                                    <img src={twitter} alt="twitter" loading="lazy" className="footer_social-icon" />
                                </a>
                            </div>
                        </div>
                        <div className="gradient-line is--footer-line"></div>
                        <div className="body-small is--centered-text">Copyright &#169; <span className="footer_copyright">2023</span> Capital Intrinsic
                        </div>
                    </div>
                </div>
            </div>


        </div>
    )
}
