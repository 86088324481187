import React from 'react'

import logo_1 from '../../Image/partners_logo/Btookfield.png'
import logo_2 from '../../Image/partners_logo/carlylenew-PhotoRoom.png'
import logo_3 from '../../Image/partners_logo/starwood-removebg-preview.png'
import logo_4 from '../../Image/partners_logo/lasalle.png'
import logo_5 from '../../Image/partners_logo/oaktree.png'
import logo_6 from '../../Image/partners_logo/blackstone-removebg-preview.png'
import logo_7 from '../../Image/partners_logo/Goldmansachs_black-removebg-preview.png'
import logo_8 from '../../Image/partners_logo/image (7).png'
import { Link } from 'react-router-dom'

export default function Partner(props) {
    const { partnerRef } = props
    return (
        <div className='2lg:mt-[16rem] md:mt-[10rem] !pt-[5em] pb-[5em] home_white-wrap relative z-10 !rounded-tl-none lg:rounded-br-[16em] rounded-br-[7em]' ref={partnerRef}>
            <div className='container mx-auto '>
                <div className=''>
                    <div>
                        <h1 className="heading-large text-[#000e41]">Our Trusted Partners</h1>
                        <div className="p-info_c"><p className="body-large text-[#000e41]">We’re proud to collaborate with industry-leading partners who share our commitment to excellence. Their expertise and support strengthen our ability to deliver exceptional value to our investors.</p></div>
                    </div>
                    <div className='grid 2md:grid-cols-4 2sm:grid-cols-3 grid-cols-2 lg:gap-[60px]  gap-[40px] justify-center pt-[2rem] client relative z-30'>
                        <div className='flex justify-center group'>
                            <a href="" className='lg:h-[60px] h-[45px]'>
                                <img src={logo_1} alt="partner-logo" className='lg:h-[60px] h-[45px] group-hover:scale-[1.1] transition-all duration-500' />
                            </a>
                        </div>
                        <div className='flex justify-center group'>
                            <a href="" className='lg:h-[60px] h-[45px]'>
                                <img src={logo_2} alt="partner-logo" className='lg:h-[60px] h-[45px] group-hover:scale-[1.1] transition-all duration-500' />
                            </a>
                        </div>
                        <div className='flex justify-center group'>
                            <a href="" className='lg:h-[60px] h-[45px]'>
                                <img src={logo_3} alt="partner-logo" className='lg:h-[60px] h-[45px] group-hover:scale-[1.1] transition-all duration-500' />
                            </a>
                        </div>
                        <div className='flex justify-center group'>
                            <a href="" className='lg:h-[60px] h-[45px]'>
                                <img src={logo_4} alt="partner-logo" className='lg:h-[60px] h-[45px] group-hover:scale-[1.1] transition-all duration-500' />
                            </a>
                        </div>
                        <div className='flex justify-center group'>
                            <a href="" className='lg:h-[60px] h-[45px]'>
                                <img src={logo_5} alt="partner-logo" className='lg:h-[60px] h-[45px] group-hover:scale-[1.1] transition-all duration-500' />
                            </a>
                        </div>
                        <div className='flex justify-center group'>
                            <a href="" className='lg:h-[60px] h-[45px]'>
                                <img src={logo_6} alt="partner-logo" className='lg:h-[60px] h-[45px] group-hover:scale-[1.1] transition-all duration-500' />
                            </a>
                        </div>
                        <div className='flex justify-center group'>
                            <a href="" className='lg:h-[60px] h-[45px]'>
                                <img src={logo_7} alt="partner-logo" className='lg:h-[60px] h-[45px] group-hover:scale-[1.1] transition-all duration-500' />
                            </a>
                        </div>
                        <div className='flex justify-center group'>
                            <a href="" className='lg:h-[60px] h-[45px]'>
                                <img src={logo_8} alt="partner-logo" className='lg:h-[60px] h-[45px] group-hover:scale-[1.1] transition-all duration-500' />
                            </a>
                        </div>
                    </div>
                </div>
                <div className=''>
                    <div className="gradient-line is--red-line"></div>
                    <Link to="Invest_With_Us" className='flex justify-center'>
                        <div className="button bg-[#fa6e68] !sm:px-[30px] !px-[20px] !sm:py-[0.95em] !py-[0.5em] group hover:bg-transparent border border-[#fa6e68] rounded-lg  transition-all duration-500 w-fit">
                            <div className="button_text-before is--navy transition-all duration-500">Invest with us</div>
                        </div>
                    </Link>
                </div>
            </div>
        </div>
    )
}
