import './App.css';
import Footer from './components/Footer';
import { useEffect, useRef } from 'react';
import Invest_With_Us from './components/Invest_With_Us';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Main from './components/Mian';



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route >
          <Route index path="/" element={<Main />} />
          <Route path="/Invest_With_Us" exact element={<Invest_With_Us />} />
        </Route>
      </Routes>
      <Footer />
    </BrowserRouter>
  );
}

export default App;
