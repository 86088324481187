import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import logo_1 from '../../Image/partners_logo/Btookfield.png'
import logo_2 from '../../Image/partners_logo/Carlyle.png'
import logo_3 from '../../Image/partners_logo/lasalle.png'
import logo_4 from '../../Image/partners_logo/oaktree.png'
import logo_5 from '../../Image/partners_logo/image (3)_prev_ui.png'


export default function BottomBar() {
    const [scrolltopdata, setscrolltopdata] = useState('');
    const height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
    const marqeeHeght = height + 570

    useEffect(() => {
        window.addEventListener('scroll', () => {
            if (window.scrollY < marqeeHeght) {
                setscrolltopdata('hidden');
            } else {
                setscrolltopdata('scrolled !flex !fixed');
            }

        });
    }, [])
    return (
        <section>
            <div>
                <div className={`${scrolltopdata} Marquee-hide top-0 left-1/2 hidden -translate-x-1/2 w-screen bg-white 2md:h-[80px] h-[65px]  items-center justify-center   z-20`}>
                    <div className="Marquee">
                        <div className="Marquee-content">
                            <div className="Marquee-tag">
                                <img src={logo_1} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_2} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_3} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_4} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_1} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_2} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_3} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_4} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_1} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_2} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_3} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_4} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_1} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_2} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_3} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_4} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_1} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_2} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_3} alt="" />
                            </div>
                            <div className="Marquee-tag">
                                <img src={logo_4} alt="" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

