import React from 'react'

import home_future_1 from '../../Image/home-future_quad-1.png'
import home_future_2 from '../../Image/home-future_quad-2.png'
import home_future_3 from '../../Image/home-future_quad-3.png'
import home_future_4 from '../../Image/home-future_quad-4.png'
import Wizard1 from '../Wizard_1'
import Wizard2 from '../Wizard_2'
import { Link } from 'react-router-dom'

export default function Portfolio(props) {
    const { approachRef } = props
    return (
        <div className='section_home-why !py-0 !block' ref={approachRef}>
            <div className="home_white-wrap sm:pb-[8.75em] pb-[4.75em] ">
                <div className="container is--large-padding">
                    <div className="section_home-future !pb-[1.375em]">
                        <div className="home-future-info-c">
                            <div className="white-wrap_heading-c">
                                <h1 className="heading-large">Our Approach to Real Estate Investment</h1>
                            </div>
                            <div className="home-future_p-c">
                                <p className="body-large">
                                    At Capital Intrinsic Real Estate Investment, we employ a time-tested approach, blending value investing wisdom with meticulous market analysis. Our focus is on unlocking the hidden potential in real estate assets, delivering sustainable growth for our investors.
                                </p>
                            </div>
                            <Link to="Invest_With_Us">
                                <div className="button bg-[#fa6e68] !sm:px-[30px] !px-[20px] !sm:py-[0.95em] !py-[0.5em] group hover:bg-transparent border border-[#fa6e68] rounded-lg  transition-all duration-500 ">
                                    <div className="button_text-before is--navy group-hover:text-white transition-all duration-500">Invest with us</div>
                                </div>
                            </Link>
                        </div>
                        <div data-w-id="2e9ef405-1706-b5dc-9069-e55f584fc8da" className="home-future_grid">
                            <div className="img-grid_column">
                                <div className="img-c">
                                    <img
                                        src={home_future_1}
                                        loading="lazy" data-w-id="003b0e6f-e5cf-7bd5-1592-3fc70f3b2837" alt=""
                                        className="image_fit-cover is--110x" />
                                </div>
                                <div className="img-c is--small-img-c">
                                    <img
                                        src={home_future_2}
                                        loading="lazy" data-w-id="8c8b2c0a-f1eb-e0a5-8d6a-32d481ca07ec" alt=""
                                        className="image_fit-cover is--110x" />
                                </div>
                            </div>
                            <div className="img-grid_column">
                                <div className="img-c is--small-img-c">
                                    <img
                                        src={home_future_3}
                                        loading="lazy" data-w-id="33e2d13e-3de0-4a8b-d55f-00c993caae86" alt=""
                                        className="image_fit-cover is--110x" />
                                </div>
                                <div className="img-c">
                                    <img
                                        src={home_future_4}
                                        loading="lazy" data-w-id="8cd6712e-ed22-ea49-73e0-a3bd6a6b7d24" alt=""
                                        className="image_fit-cover is--110x" />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="gradient-line is--red-line"></div>
                </div>
                <div className="container">
                    <div className="sectiion_home-smarter items-center !sm:pb-[3em] !pb-[1em]">
                        <div data-w-id="ebd10edd-7598-1c90-3282-18f1c95c5ca6" className="home-smarter_img-c">
                            <img
                                src="https://uploads-ssl.webflow.com/62bc63a42ce68cd398f4eb1d/62cdbc33a1a1de7229ce8782_home-smarter_mark.svg"
                                loading="lazy" alt="" className="home-smarter_mark" />
                            <img
                                src="../uploads-ssl.webflow.com/62bc63a42ce68cd398f4eb1d/62cdbc0d2a8b9862152cc68f_home-smarter_building.png"
                                loading="lazy" alt="" sizes="(max-width: 991px) 88vw, 38vw"
                                srcSet="https://uploads-ssl.webflow.com/62bc63a42ce68cd398f4eb1d/62cdbc0d2a8b9862152cc68f_home-smarter_building-p-500.png 500w, https://uploads-ssl.webflow.com/62bc63a42ce68cd398f4eb1d/62cdbc0d2a8b9862152cc68f_home-smarter_building.png 778w"
                                className="home-smarter_building" />
                        </div>
                        <div className="home-smarter_info-c">
                            <div className="white-wrap_heading-c">
                                <h1 className="heading-large">Invest with Confidence</h1>
                            </div>
                            <div className="p-info_c">
                                <p className="body-large">
                                    At Capital Intrinsic Real Estate Investment, we believe in the power of exclusivity. Our investment opportunities are tailored for individuals and institutions who understand the significance of trust and discretion in wealth management. To begin your journey with us and determine if you qualify for a world of exclusive investments, please provide your email below.
                                </p>
                            </div>
                            <Link to="Invest_With_Us">
                                <div className="button bg-[#fa6e68] !sm:px-[30px] !px-[20px] !sm:py-[0.95em] !py-[0.5em] group hover:bg-transparent border border-[#fa6e68] rounded-lg  transition-all duration-500 ">
                                    <div className="button_text-before is--navy group-hover:text-white transition-all duration-500">Invest with us</div>
                                </div>
                            </Link>
                        </div>
                    </div>
                    <div className="gradient-line is--red-line"></div>
                </div>
                <Wizard1 />
                <Wizard2 />
            </div>
        </div>
    )
}
