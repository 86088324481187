import React, { useRef } from 'react'
import Header from '../Navbar'
import Home from '../Home'
import Partner from '../Partner'
import About from '../About'
import BottomBar from '../Botttom_Bar'
import Portfolio from '../Portfolio'




export default function Main() {
    const aboutRef = useRef(null);
    const approachRef = useRef(null);
    const partnerRef = useRef(null);

    const closeRef = useRef(null)
    const handleClick = (loc) => {
        closeRef.current.click();
        if (loc == 'about')
            aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
        else if (loc == 'approach')
            approachRef.current?.scrollIntoView({ behavior: 'smooth' });
        else if (loc == 'partner')
            partnerRef.current?.scrollIntoView({ behavior: 'smooth' });
        else
            aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
    }
    return (
        <div>
            <div className='body_dark'>
                <div className='body_overflow'>
                    <div className='cursor-default'>
                        <Header handleClick={handleClick} closeRef={closeRef} />
                        <Home />
                        <Partner partnerRef={partnerRef} />
                        <About aboutRef={aboutRef} />
                        <BottomBar />
                        <Portfolio approachRef={approachRef} />
                    </div>
                </div>
            </div>
        </div >
    )
}
