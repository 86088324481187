import React from 'react'
import { UserState } from '../service';

export default function Wizard2() {
    const { isSubmitted, showComponent } = UserState();



    return (
        <div>
            {isSubmitted == true ? (
                <div className='container mx-auto sm:pt-[4rem]  z-[32] relativ '>
                    <div className=''>
                        <div>
                            <div>
                                <h1 className="heading-large">Your Exclusive Investment Journey Begins Now</h1>
                                <div className="p-info_c"><p className="body-large">Thank you for expressing your interest in Capital Intrinsic. To ensure that our offerings align with your goals, please take a moment to answer a few questions in our questionnaire.</p></div>
                            </div>
                        </div>
                    </div>
                    <div className="form-group rounded-lg bg-[#0a1439] p-[20px] !mb-[0px]">
                        <div className='grid 2md:grid-cols-3 gap-[.75rem] py-[.625rem] '>
                            <div className=''>
                                <p className='text-[#fff] font-normal pb-[.25rem] input-label'>Full name</p>
                                <input className='input py-[.625rem] px-[.9375rem] bg-[#ffffff] w-full rounded-[8px] text-[#0a1439] focus:outline-none text-[1rem]' type="text" placeholder='Enter Your Name' />
                            </div>
                            <div className=''>
                                <p className='text-[#fff] font-normal pb-[.25rem] input-label'>Email</p>
                                <input className='input py-[.625rem] px-[.9375rem] bg-[#ffffff] w-full rounded-[8px] text-[#0a1439] focus:outline-none text-[1rem]' type="email" placeholder='Enter Your Email' />
                            </div>
                            <div className=''>
                                <p className='text-[#fff] font-normal pb-[.25rem] input-label'>Mobile</p>
                                <input className='input py-[.625rem] px-[.9375rem] bg-[#ffffff] w-full rounded-[8px] text-[#0a1439] focus:outline-none text-[1rem]' type="text" placeholder='Enter Your Phone' />
                            </div>
                        </div>
                        <div className='pb-[16px] pt-[10px]'>
                            <p className='text-[16px] font-normal text-[#fff]'>Are you an accredited investor?</p>
                            <div className='flex items-center justify-start gap-[10px] pt-[8px]'>
                                <div className='flex items-center justify-start gap-[4px]'>
                                    <input className='w-[16px] h-[16px]' type="radio" value="" name='qts' id='yes' />
                                    <label className='text-[16px] font-normal text-[#fff] mb-0' htmlFor='yes'>Yes</label>
                                </div>
                                <div className='flex items-center justify-start gap-[4px]'>
                                    <input className='w-[16px] h-[16px]' type="radio" value="" name='qts' id='no' />
                                    <label className='text-[16px] font-normal text-[#fff] mb-0' htmlFor='no'>No</label>
                                </div>
                            </div>
                        </div>
                        <div className='2md:flex items-center justify-between mt-[.625rem]'>
                            <div className='flex 2md:items-center justify-start gap-[.5rem]'>
                                <input type="checkbox" name="" className='w-[.9375rem] bg-[#205168] h-[.9375rem] 2md:mt-0 mt-[5px]' id="terms" />
                                <label htmlFor="terms" className='!mb-0  !text-[16px] !font-normal text-white'>I agree to Capital Intrinsic’s <a href="" className='underline'>Terms and Privacy Policy</a>.</label>
                            </div>
                            <div className="2md:mt-0 mt-[16px]  button bg-[#fa6e68] !px-[30px] !py-[10px] rounded-lg group hover:bg-transparent border border-[#fa6e68]  transition-all duration-500">
                                <div className="button_text-before is--navy group-hover:text-white transition-all duration-500">Submit</div>
                            </div>
                        </div>
                    </div>
                    {/* <div className="gradient-line is--red-line"></div> */}
                </div>) : (<></>)
            }
        </div>
    )
}
