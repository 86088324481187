import { createContext, useContext, useEffect, useState } from "react";
import React from 'react'

const UserContext = createContext();

const UserProvider = ({ children }) => {

    const [isSubmitted, setIsSubmitted] = useState(false);
    const [showComponent, setShowComponent] = useState(true);

    const handleSubmit = () => {
        setIsSubmitted(true);
    };


    return (
        <UserContext.Provider value={{ setIsSubmitted, handleSubmit, isSubmitted, showComponent, setShowComponent }}>
            {children}
        </UserContext.Provider>
    )
}
export const UserState = () => {
    return useContext(UserContext);
}
export default UserProvider;