import emailjs from '@emailjs/browser';
import Modal from 'react-modal';
import React, { useState } from 'react'
import { UserState } from '../service';
import '../../App.css';
import { useEffect } from 'react';

export default function Wizard1() {
    // const [wobble, setWobble] = useState(0)

    const { handleSubmit, isSubmitted } = UserState();
    const [addContact, setaddContact] = useState(true);
    const [email, setEmail] = useState('')
    const [fistname, setfistname] = useState('')
    const [lastname, setlastname] = useState('')
    const [thanks, setthanks] = useState(false)
    const [wobble, setWobble] = React.useState(0)
    const [thanksMessage, setthanksMessage] = React.useState('')
    const [islodding, setIsloading] = useState(false)


    // const closeModal = () => {
    //   // Close the modal and perform any necessary cleanup
    //   setModalOpen(false);
    // };
    useEffect(() => {
        setTimeout(() => {
            setthanks(false)
            setaddContact(true)
            
        }, 3000)
        // console.log('effect', thanks)

    }, [thanks])

    if (isSubmitted) {
        // If submitted, you can return null or another component
        return null;
    }
    function ShowConatact() {

        if (setaddContact == false) {
            setaddContact(true)
        } else {
            setaddContact(false)
        }
    }



    function SendMail() {
        // console.log('----=first')
        const templateParams = {
            name: fistname + " " + lastname,
            email: email
        };

        emailjs.send('service_y16gpzc', 'template_bnhkiql', templateParams, 'NDxAFK6t2hc55PBba')
            .then((response) => {
                console.log('SUCCESS!', response.status, response.text);
                setthanksMessage('Thank you! An email will be sent to you shortly.')
                setthanks(true)
                // ThankModal()
            }, (err) => {
                setthanksMessage('Something Went Wrrong !!!!!!')
                setthanks(true)
                console.log('FAILED...', err);
            });
        setEmail('')
        setWobble(1)
        setfistname('')
        setlastname('')
    }


    return (
        <div>
            {/* modal  */}
            <div>
                {/* <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Thank You Modal"
        className='z-[88]'
      >
        <h2>Thank You!</h2>
        <p>Your form has been submitted successfully.</p>
        <button onClick={closeModal}>Close</button>
      </Modal> */}
            </div>
            <div className='container mx-auto sm:pt-[4rem]  z-[32] relative'>
                <div>
                    <h1 className="heading-large">Contact Us</h1>
                    <div className="p-info_c"><p className="body-large">Thank you for expressing your interest in Capital Intrinsic. To ensure that our offerings align with your goals, please take a moment to answer a few questions in our questionnaire.</p></div>

                </div>
                {thanks == true ? (<div className='bg-[#0a1439] text-[#fff] rounded-lg p-[30px] PopIn' onAnimationEnd={() => setWobble(0)}
                    wobble={wobble}
                ><p className=' text-xl '>{thanksMessage}</p></div>)
                    : (<div className="form-group rounded-lg bg-[#0a1439] p-[20px] !mb-[0px] " >
                        <p className='text-[#fff] text-[1rem] font-normal pb-[.25rem] input-label'>Email address</p>
                        <input className='input py-[.625rem] px-[.9375rem] bg-[#ffffff] w-full rounded-[8px] text-[#0a1439] focus:outline-none text-[1rem]'
                            id="email"
                            name="email"
                            type="email"
                            placeholder="Enter your email"
                            onChange={(e) => { setEmail(e.target.value) }}
                            value={email}
                        />
                        {addContact == false ? <div className='grid md:grid-cols-2 grid-cols-1 gap-[15px] pt-[15px]'>
                            <div>
                                <p className='text-[#fff] text-[1rem] font-normal pb-[.25rem] input-label'>First name</p>
                                <input className='input py-[.625rem] px-[.9375rem] bg-[#ffffff] w-full rounded-[8px] text-[#0a1439] focus:outline-none text-[1rem]'
                                    id="firstname"
                                    name="firstname"
                                    type="text"
                                    placeholder="Enter your first name"
                                    onChange={(e) => { setfistname(e.target.value) }}
                                    value={fistname}
                                />
                            </div>
                            <div>
                                <p className='text-[#fff] text-[1rem] font-normal pb-[.25rem] input-label'>Last name</p>
                                <input className='input py-[.625rem] px-[.9375rem] bg-[#ffffff] w-full rounded-[8px] text-[#0a1439] focus:outline-none text-[1rem]'
                                    id="lastname"
                                    name="lastname"
                                    type="text"
                                    placeholder="Enter your last name"
                                    onChange={(e) => { setlastname(e.target.value) }}
                                    value={lastname}
                                />
                            </div>

                        </div> : <></>}
                        {/* <div onClick={btn}>
                                jml;mkqwl;dmqwl;dmfl;w
                    </div> */}
                        <div className='flex justify-end'>
                            {/* <button onClick={handleSubmit} type='submit' className=' py-[.625rem]  px-[1.25rem] bg-[#0a1439] text-white rounded-[.5rem]'>Submit</button> */}
                            {addContact == false ? (<div onClick={SendMail} type='submit' className=" button mt-[16px] bg-[#fa6e68] !px-[30px] !py-[10px] rounded-lg group hover:bg-transparent border border-[#fa6e68]  transition-all duration-500">
                                <div className="button_text-before is--navy group-hover:text-white transition-all duration-500">Submit</div>
                            </div>) : (
                                <div onClick={ShowConatact} className="button mt-[16px] bg-[#fa6e68] !px-[30px] !py-[10px] rounded-lg group hover:bg-transparent border border-[#fa6e68]  transition-all duration-500">
                                    <div className="button_text-before is--navy group-hover:text-white transition-all duration-500">Submit</div>
                                </div>)
                            }
                        </div>
                    </div>)}
            </div>
        </div>
    )
}



