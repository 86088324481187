import emailjs from '@emailjs/browser';
import React, { useRef } from 'react'
import Header from '../Navbar'
import { Link, redirect, useNavigate } from 'react-router-dom';
import Select, { selectClasses } from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Input } from '@mui/joy';
import New_Investors from '../../Image/[Downloader.la]-654b1d1294bfe.jpg'
import { useState } from 'react';
import { Toast } from 'primereact/toast';
import { useEffect } from 'react';



export default function Invest_With_Us() {
    const toastCenter = useRef(null);
    const navigate = useNavigate();

    const [firstname, setFirstname] = useState(null)
    const [lastname, setLastname] = useState(null)
    const [email, setEmail] = useState(null)
    const [mobile, setMobile] = useState(null)
    const [investor, setInvestor] = useState(null)
    const [profession, setProfession] = useState(null)
    const [industry, setIndustry] = useState(null)
    const [netWorth, setNetWorth] = useState(null)
    const [amount, setAmount] = useState(null)
    const [cat, setCat] = useState(null)
    const [lookinAmount, setLookinAmount] = useState(null)
    const [time, setTime] = useState(null)
    const [disrupt, setDisrupt] = useState(null)
    const [message, setMessage] = useState(null)
    const [thanks, setthanks] = useState(false)
    const [wobble, setWobble] = React.useState(0)
    const [thanksMessage, setthanksMessage] = React.useState('')
    const [addContact, setaddContact] = useState(true);
    const [islodding, setIsloading] = useState(false)



    const aboutRef = useRef(null);
    const approachRef = useRef(null);
    const partnerRef = useRef(null);
    const closeRef = useRef(null);

    useEffect(() => {
        setTimeout(() => {
            setthanks(false)
            setaddContact(true)
        }, 3000)
        // console.log('effect', thanks)
        
    }, [thanks])


    const handleClick = (loc) => {
        closeRef.current.click();
        if (loc == 'about')
            aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
        else if (loc == 'approach')
            approachRef.current?.scrollIntoView({ behavior: 'smooth' });
        else if (loc == 'partner')
            partnerRef.current?.scrollIntoView({ behavior: 'smooth' });
        else
            aboutRef.current?.scrollIntoView({ behavior: 'smooth' });
    }

    const [investorTypes, setInvestorTypes] = useState({
        types: []
    });

    const handleChange = async (e) => {
        const { value, checked } = e.target;
        const { types } = investorTypes;

        if (checked) {
            await setInvestorTypes({
                types: [...types, value],
            });
        }
        else {
            await setInvestorTypes({
                types: types.filter(
                    (e) => e !== value
                )
            });
        }
    };

    function SendMail(e) {
        // setIsloading(true);
        e.preventDefault();
        let err = 0;
        const templateParams = {
            name: firstname + " " + lastname,
            email: email,
            mobile: mobile,
            investor: investor,
            profession: profession,
            industry: industry,
            netWorth: netWorth,
            amount: amount,
            cat: investorTypes.types.toString(),
            lookinAmount: lookinAmount,
            time: time,
            disrupt: disrupt,
            message: message,

        };
        // console.log(investorTypes.types, investorTypes.types.length, firstname, lastname, email, mobile, investor, netWorth, amount, time)
        if (firstname == '' && lastname == '') {
            err = 1;
            toastCenter.current.show({ severity: 'error', detail: 'Please enter your name', life: 2000 });
        }
        if (email == '') {
            err = 1;
            toastCenter.current.show({ severity: 'error', detail: 'Please enter your email address', life: 2000 });
        }
        if (mobile == '') {
            err = 1;
            toastCenter.current.show({ severity: 'error', detail: 'Please enter your mobile', life: 2000 });
        }
        if (investorTypes.types.length == 0) {
            err = 1;
            toastCenter.current.show({ severity: 'error', detail: 'Please select any of investor type', life: 2000 });
        }
        if (investor == '' || netWorth == '' || amount == '' || time == '') {
            err = 1;
            toastCenter.current.show({ severity: 'error', detail: 'Please select proper value', life: 2000 });
        }


        // console.log(templateParams, investorTypes.types);
        if (err == 0) {
            setIsloading(true);
            console.log('innnnn')
            emailjs.send('service_y16gpzc', 'template_xc0liwz', templateParams, 'NDxAFK6t2hc55PBba')
                .then((response) => {
                    console.log('SUCCESS!', response.status, response.text);
                    const label = 'Thank You, Submit Successfully';
                    setthanksMessage('Thank you! An email will be sent to you shortly.')
                    setthanks(true)
                    toastCenter.current.show({ severity: 'success', summary: 'Success', detail: label, life: 2000 });
                    setTimeout(() => {
                        navigate('/')
                    }, 3000)
                }, (err) => {
                    console.log('FAILED...', err);
                    const label = 'Something went wrong';
                    setthanksMessage('Something Went Wrrong !!!!!!')
                    setthanks(true)
                    toastCenter.current.show({ severity: 'error', summary: 'Failed', detail: label, life: 2000 });
                });
            setWobble(1)
            setFirstname("")
            setLastname("")
            setEmail('')
            setMobile('')
            setInvestor('')
            setProfession('')
            setIndustry(null)
            setNetWorth(null)
            setAmount(null)
            setLookinAmount(null)
            setTime(null)
            setDisrupt('')
            setMessage('')
        }
        
        // setIsloading(false);
    }

    return (
        <>
            <Toast ref={toastCenter} position="top-right" />

            <div className='body_dark'>
                <div className='body_overflow'>
                    <Header handleClick={handleClick} closeRef={closeRef} />
                </div>
            </div>
            <div className='body_light'>
                <div className='invest_bg_img'>
                    <div className='overly-invest'></div>
                    <div className='container relative z-[2] '>
                        <h2 className="heading-large is--white text-center opacity-[0.7]">Invest With Us</h2>
                        <div className='flex justify-center items-center gap-[6px]'>
                            <Link to="/">
                                <p className="body-large text-white !mt-0">Home</p>
                            </Link>
                            <div>
                                <svg xmlns="http://www.w3.org/2000/svg" className='opacity-[0.7] lucide lucide-chevron-right' width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="#fff" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"><path d="m9 18 6-6-6-6" /></svg>
                            </div>
                            <p className="body-large text-white !mt-0">Invest With Us</p>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="section_hiw-level !pb-[4.75em]">
                        <div className="hiw-level_img-c">
                            <div data-w-id="713cf920-c428-1b1c-f2a6-f134cf06cd43" className="hiw-level_img-c-absolute">
                                <img
                                    src={New_Investors}
                                    loading="lazy" alt="" sizes="(max-width: 991px) 88vw, 38vw"
                                    className="moving-img" /></div>
                        </div>
                        <div className="hiw-level_info-c">
                            <div className="white-wrap_heading-c">
                                <h1 className="heading-large">Currently Accepting New Investors</h1>
                            </div>
                            <div className="p-info_c">
                                <p className="body-large">
                                    Capital Intrinsic invites you to join an exclusive alliance of investors, partnering with preeminent firms like Blackstone, Brookfield, and Goldman Sachs. Our selective network is crafted for both accredited and select non-accredited clients, aiming to deliver a distinguished investment experience.
                                    <br /><br />
                                    Embrace the opportunity to invest alongside industry giants in premium real estate assets. Begin by applying to our network; if selected, you’ll enjoy tailored investment proposals and the chance to become a limited partner in our private offerings.
                                    <br /><br />
                                    To proceed, please fill out the form below with your information.
                                    Elevate your portfolio with Capital Intrinsic, where exclusive access meets strategic opportunity.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <form action="" >
                    <div className='container'>
                        {thanks == true ? (
                            <div className='container'>
                                <div className='h-[200px]'>
                                    <div className='bg-[#0a1439] text-[#fff] rounded-lg p-[30px] PopIn' onAnimationEnd={() => setWobble(0)}
                                        wobble={wobble}>
                                        <p className=' text-xl '>{thanksMessage}</p>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div className='section_hiw-level !pt-0 !block'>

                                <div className="form-group rounded-lg bg-[#0a1439] p-[20px] !mb-[0px] invest_with_uc_form mt-[2rem]">
                                    <div className="p-info_c !mb-[1rem]">
                                        <p className="body-large text-white !opacity-100 !mt-0">To join our investor list, please complete the form below!</p>
                                    </div>
                                    <div className='grid 2lg:grid-cols-3 md:grid-cols-2 gap-[8px] py-[.625rem] '>
                                        <div className='mb-[12px]'>
                                            <p className='text-white !opacity-100 font-normal pb-[.25rem] input-label'>First name<span className='text-red-600  	'>*</span> </p>
                                            <Input onChange={(e) => { setFirstname(e.target.value) }} value={firstname} placeholder="Type in here…" required />
                                        </div>
                                        <div className='mb-[12px]'>
                                            <p className='text-white !opacity-100 font-normal pb-[.25rem] input-label'>Last name<span className='text-red-600   	'>*</span> </p>
                                            <Input onChange={(e) => { setLastname(e.target.value) }} value={lastname} placeholder="Type in here…" required />
                                        </div>
                                        <div className='mb-[12px]'>
                                            <p className='text-white !opacity-100 font-normal pb-[.25rem] input-label'>Email<span className='text-red-600   	'>*</span> </p>
                                            <Input type='email' onChange={(e) => { setEmail(e.target.value) }} value={email} placeholder="Type in here…" required />
                                        </div>
                                        <div className='mb-[12px]'>
                                            <p className='text-white !opacity-100 font-normal pb-[.25rem] input-label'>Mobile<span className='text-red-600  	'>*</span> </p>
                                            <Input type='tel' onChange={(e) => { setMobile(e.target.value) }} value={mobile} placeholder="Type in here…" required />
                                        </div>
                                        <div className='mb-[12px]'>
                                            <p className='text-white !opacity-100 font-normal pb-[.25rem] input-label'>Are you an accredited investor?<span className='text-red-600 	'>*</span> </p>
                                            <Select value={investor}
                                                className='w-full'
                                                required
                                                placeholder="Select here..."
                                                onChange={(e, val) => { setInvestor(val) }}
                                                sx={{
                                                    width: 240,
                                                    [`& .${selectClasses.indicator}`]: {
                                                        transition: '0.2s',
                                                        [`&.${selectClasses.expanded}`]: {
                                                            transform: 'rotate(-180deg)',
                                                        },
                                                    },
                                                }}

                                            >
                                                <Option key="yes" value="yes">Yes</Option>
                                                <Option key="no" value="no">No</Option>
                                            </Select>
                                        </div>
                                        <div className='mb-[12px]'>
                                            <p className='text-white !opacity-100 font-normal pb-[.25rem] input-label'>Profession</p>
                                            <Input placeholder="Type in here…" onChange={(e) => { setProfession(e.target.value) }} value={profession} />
                                        </div>
                                        <div className='mb-[12px]'>
                                            <p className='text-white !opacity-100 font-normal pb-[.25rem] input-label'>Industry</p>
                                            <div className=" bg-white rounded-[8px]  w-full">
                                                <Select
                                                    className='w-full'
                                                    placeholder="Select here…"
                                                    value={industry}
                                                    onChange={(e, val) => { setIndustry(val) }}
                                                    sx={{
                                                        width: 240,
                                                        [`& .${selectClasses.indicator}`]: {
                                                            transition: '0.2s',
                                                            [`&.${selectClasses.expanded}`]: {
                                                                transform: 'rotate(-180deg)',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Option value="Legal">Legal</Option>
                                                    <Option value="Medical">Medical</Option>
                                                    <Option value="Technology">Technology</Option>
                                                    <Option value="Manufacturing">Manufacturing</Option>
                                                    <Option value="Energy">Energy</Option>
                                                    <Option value="Real Estate">Real Estate</Option>
                                                    <Option value="Finance">Finance</Option>
                                                    <Option value="Agriculture">Agriculture</Option>
                                                    <Option value="Education">Education</Option>
                                                    <Option value="Othre">Othre</Option>
                                                </Select>
                                            </div>
                                        </div>
                                        <div className='mb-[12px]'>
                                            <p className='text-white !opacity-100 font-normal pb-[.25rem] input-label'>Approximate Net Worth<span className='text-red-600   	'>*</span> </p>
                                            <div className=" bg-white rounded-[8px]  w-full">
                                                <Select
                                                    className='w-full'
                                                    placeholder="Select here…"
                                                    value={netWorth}
                                                    required
                                                    onChange={(e, val) => { setNetWorth(val) }}
                                                    sx={{
                                                        width: 240,
                                                        [`& .${selectClasses.indicator}`]: {
                                                            transition: '0.2s',
                                                            [`&.${selectClasses.expanded}`]: {
                                                                transform: 'rotate(-180deg)',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Option value="$0 - $250,000">$0 - $250,000</Option>
                                                    <Option value="$250,000 - $500,000">$250,000 - $500,000</Option>
                                                    <Option value="$500,000 - $1M">$500,000 - $1M</Option>
                                                    <Option value="$1M - $3M">$1M - $3M</Option>
                                                    <Option value="$1M - $5M">$1M - $5M</Option>
                                                    <Option value="$5M - $8M">$5M - $8M</Option>
                                                    <Option value="$8M - $10M">$8M - $10M</Option>
                                                    <Option value="$10M + ">$10M + </Option>
                                                </Select>
                                            </div>
                                        </div>
                                        <div className='mb-[12px]'>
                                            <p className='text-white !opacity-100 font-normal pb-[.25rem] input-label'>How much do you have available to invest?<span className='text-red-600   	'>*</span> </p>
                                            <div className=" bg-white rounded-[8px]  w-full">
                                                <Select
                                                    className='w-full'
                                                    placeholder="Select here…"
                                                    value={amount}
                                                    required
                                                    onChange={(e, val) => { setAmount(val) }}
                                                    sx={{
                                                        width: 240,
                                                        [`& .${selectClasses.indicator}`]: {
                                                            transition: '0.2s',
                                                            [`&.${selectClasses.expanded}`]: {
                                                                transform: 'rotate(-180deg)',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Option value="$0 - $24,000">$0 - $24,000</Option>
                                                    <Option value="$25,000 - $49,000">$25,000 - $49,000</Option>
                                                    <Option value="$50,000 - $99,000">$50,000 - $99,000</Option>
                                                    <Option value="$100,000 - $249,000">$100,000 - $249,000</Option>
                                                    <Option value="$250,000 - $499,000">$250,000 - $499,000</Option>
                                                    <Option value="$500,000 - $1M">$500,000 - $1M</Option>
                                                    <Option value="$1M - $3M">$1M - $3M</Option>
                                                    <Option value="$3M - $5M">$3M - $5M</Option>
                                                    <Option value="$5M +">$5M +</Option>
                                                </Select>
                                            </div>
                                        </div>
                                        <div className='2lg:col-span-3 md:col-span-2'>
                                            <div>
                                                <p className='font-normal input-label text-white !opacity-100'>Which of the following are you? (check all that apply)<span className='text-red-600  	'>*</span> </p>
                                            </div>
                                            <div className=' items-center justify-between mt-[.625rem]'>
                                                <div className='grid 2lg:grid-cols-3 md:grid-cols-2'>
                                                    <div className='flex 2md:items-center justify-start gap-[.5rem] '>
                                                        <input type="checkbox" name="investor_type" className='w-[.9375rem] bg-[#205168] h-[.9375rem] 2md:mt-0 mt-[5px]' id="Passive_Investor" value="Passive Investor" onChange={handleChange} />
                                                        <label htmlFor="Passive_Investor" className='!mb-0  !text-[16px] !font-normal text-white'>Passive Investor</label>
                                                    </div>
                                                    <div className='flex 2md:items-center justify-start gap-[.5rem]'>
                                                        <input type="checkbox" name="investor_type" className='w-[.9375rem] bg-[#205168] h-[.9375rem] 2md:mt-0 mt-[5px]' id="Operator" value="Operator" onChange={handleChange} />
                                                        <label htmlFor="Operator" className='!mb-0  !text-[16px] !font-normal text-white'>Operator/Syndicator</label>
                                                    </div>
                                                    <div className='flex 2md:items-center justify-start gap-[.5rem]'>
                                                        <input type="checkbox" name="investor_type" className='w-[.9375rem] bg-[#205168] h-[.9375rem] 2md:mt-0 mt-[5px]' id="Multifamily_Vendor" value="Multifamily Vendor" onChange={handleChange} />
                                                        <label htmlFor="Multifamily_Vendor" className='!mb-0  !text-[16px] !font-normal text-white'>Multifamily Vendor </label>
                                                    </div>
                                                    <div className='flex 2md:items-center justify-start gap-[.5rem]'>
                                                        <input type="checkbox" name="investor_type" className='w-[.9375rem] bg-[#205168] h-[.9375rem] 2md:mt-0 mt-[5px]' id="Broker" value="Broker" onChange={handleChange} />
                                                        <label htmlFor="Broker" className='!mb-0  !text-[16px] !font-normal text-white'>Broker </label>
                                                    </div>
                                                    <div className='flex 2md:items-center justify-start gap-[.5rem]'>
                                                        <input type="checkbox" name="investor_type" className='w-[.9375rem] bg-[#205168] h-[.9375rem] 2md:mt-0 mt-[5px]' id="New_to_Real_Estate" value="New to Real Estate" onChange={handleChange} />
                                                        <label htmlFor="New_to_Real_Estate" className='!mb-0  !text-[16px] !font-normal text-white'>New to Real Estate </label>
                                                    </div>
                                                    <div className='flex 2md:items-center justify-start gap-[.5rem]'>
                                                        <input type="checkbox" name="investor_type" className='w-[.9375rem] bg-[#205168] h-[.9375rem] 2md:mt-0 mt-[5px]' id="Other" value="Other" onChange={handleChange} />
                                                        <label htmlFor="Other" className='!mb-0  !text-[16px] !font-normal text-white'>Other </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='mb-[12px]'>
                                            <p className='text-white !opacity-100 font-normal pb-[.25rem] input-label'>How much are you looking to invest?<span className='text-red-600 	'>*</span> </p>
                                            <div className=" bg-white rounded-[8px]  w-full">
                                                <Select
                                                    className='w-full'
                                                    placeholder="Select in here…"
                                                    value={lookinAmount}
                                                    required
                                                    onChange={(e, val) => { setLookinAmount(val) }}
                                                    sx={{
                                                        width: 240,
                                                        [`& .${selectClasses.indicator}`]: {
                                                            transition: '0.2s',
                                                            [`&.${selectClasses.expanded}`]: {
                                                                transform: 'rotate(-180deg)',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Option value="$25,000 - $49,000">$25,000 - $49,000</Option>
                                                    <Option value="$50,000 - $99,000">$50,000 - $99,000</Option>
                                                    <Option value="$100,000 - $249,000">$100,000 - $249,000</Option>
                                                    <Option value="$250,000 - $499,000">$250,000 - $499,000</Option>
                                                    <Option value="$500,000+">$500,000+</Option>
                                                </Select>
                                            </div>
                                        </div>
                                        <div className='mb-[12px]'>
                                            <p className='text-white !opacity-100 font-normal pb-[.25rem] input-label'>When are you looking to invest in a deal?<span className='text-red-600   	'>*</span> </p>
                                            <div className=" bg-white rounded-[8px]  w-full">
                                                <Select
                                                    className='w-full'
                                                    placeholder="Select in here…"
                                                    value={time}
                                                    required
                                                    onChange={(e, val) => { setTime(val) }}
                                                    sx={{
                                                        width: 240,
                                                        [`& .${selectClasses.indicator}`]: {
                                                            transition: '0.2s',
                                                            [`&.${selectClasses.expanded}`]: {
                                                                transform: 'rotate(-180deg)',
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <Option value="As soon as possible">As soon as possible</Option>
                                                    <Option value="Within 3 months">Within 3 months</Option>
                                                    <Option value="6+ months from now">6+ months from now</Option>
                                                </Select>
                                            </div>
                                        </div>
                                        <div className='mb-[12px]'>
                                            <p className='text-white !opacity-100 font-normal pb-[.25rem] input-label'>How did you first hear about Capital Intrinsic?<span className='text-red-600    	'>*</span> </p>
                                            <Input placeholder="Type in here…" value={disrupt} onChange={(e) => { setDisrupt(e.target.value) }} required />
                                        </div>

                                        <div className='mb-[12px] 2lg:col-span-3 md:col-span-2'>
                                            <p className='text-white !opacity-100 font-normal pb-[.25rem] input-label'>Message</p>
                                            <textarea value={message} onChange={(e) => { setMessage(e.target.value) }} className=' py-[.625rem] px-[.9375rem] bg-[#ffffff] w-full rounded-[8px]  text-[#0a1439] focus:outline-none text-[1rem] placeholder:text-[14px] placeholder:text-[#8390a2] font-medium' type="text" placeholder='Type in here...' ></textarea>
                                        </div>
                                    </div>
                                    <div onClick={SendMail} className='flex justify-end'>
                                        <div type='submit' className="2md:mt-0  button bg-[#fa6e68] !px-[30px] !py-[10px] rounded-lg group  border border-[#fa6e68]  transition-all duration-500 w-fit">
                                            <div className="button_text-before is--navy group-hover:text-white transition-all duration-500">
                                                {islodding == false ? <span>Submit</span> : <><svg className='w-[60px] h-[30px]' xmlns="http://www.w3.org/2000/svg" viewBox="0 0 200 200"><circle fill="#0A1439" stroke="#0A1439" stroke-width="15" r="15" cx="40" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.4"></animate></circle><circle fill="#0A1439" stroke="#0A1439" stroke-width="15" r="15" cx="100" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="-.2"></animate></circle><circle fill="#0A1439" stroke="#0A1439" stroke-width="15" r="15" cx="160" cy="100"><animate attributeName="opacity" calcMode="spline" dur="2" values="1;0;1;" keySplines=".5 0 .5 1;.5 0 .5 1" repeatCount="indefinite" begin="0"></animate></circle></svg></>}
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>)
                        }
                    </div>
                </form>


            </div>

        </>
    )
}
