import React from 'react'
import {
    Drawer,
    Button,
    Typography,
    IconButton,
} from "@material-tailwind/react";
import { Link } from 'react-router-dom';


export default function Header(props) {
    const { handleClick, closeRef } = props;
    const [openTop, setOpenTop] = React.useState(false);

    const openDrawerTop = () => setOpenTop(true);
    const closeDrawerTop = () => setOpenTop(false);

    return (
        <React.Fragment>
            <div>
                <div className="">
                    <div className='nav w-full'>
                        <header className='w-full'>
                            <nav className="w-full z-20 top-0 left-0">
                                <ul className="navigation max-w-[90vw] flex flex-wrap justify-between items-center relative mx-auto 2md:py-8 py-4 !mb-0 ps-0">
                                    <Link to="/" aria-current="page" className="nav_logo-link w-inline-block w--current text-white text-[32px] !h-auto logo">
                                    Capital Intrinsic
                                    </Link>
                                    <input type="checkbox" id="check" />
                                    <span className="menu flex items-center 2md:gap-[32px] [&>li]:text-center [&>li]:text-[16px] [&>li]:relative [&>li]:transition [&>li]:duration-500 [&>li]:ease-in-out [&>li]:font-medium">
                                        <li className='hover:text-[#fa6e68] cursor-pointer' onClick={() => handleClick('about')}>
                                           <Link to="/">About</Link> 
                                        </li>
                                        <li className='hover:text-[#fa6e68] cursor-pointer ' onClick={() => handleClick('approach')}>
                                           <Link to="/">Approach</Link> 
                                        </li>
                                        <li className='hover:text-[#fa6e68] cursor-pointer' onClick={() => handleClick('partner')}>
                                           <Link to="/">Partners</Link> 
                                        </li>
                                        <li>
                                            <Link to="/Invest_With_Us">
                                                <div className="button bg-[#fa6e68] !sm:px-[30px] !px-[20px] !sm:py-[0.95em] !py-[0.5em] group hover:bg-transparent border border-[#fa6e68] rounded-lg  transition-all duration-500 ">
                                                    <div className="button_text-before is--navy group-hover:text-white transition-all duration-500">Invest with us</div>
                                                </div>
                                            </Link>
                                        </li>
                                        <label ref={closeRef} htmlFor="check" className="close-menu">X</label>
                                    </span>
                                    <div className="open-menu">
                                        <label htmlFor="check" className="menu_button-text open-menu m-0">
                                            <div>
                                                <svg xmlns="http://www.w3.org/2000/svg" className='lucide lucide-menu h-[28px] w-[28px]' width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" ><line x1="4" x2="20" y1="12" y2="12" /><line x1="4" x2="20" y1="6" y2="6" /><line x1="4" x2="20" y1="18" y2="18" /></svg>
                                            </div>
                                        </label>
                                    </div>
                                </ul>
                            </nav>
                        </header>
                    </div>
                </div >
            </div >
        </React.Fragment>
    )
}


