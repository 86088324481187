import React, { useEffect, useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';

// import required modules
import { Autoplay, EffectCoverflow, Pagination } from 'swiper/modules';

import slider_1 from '../../Image/home-hero_img-1.png'
import slider_2 from '../../Image/home-hero_img-2.png'
import slider_3 from '../../Image/home-hero_img-3.png'
import slider_4 from '../../Image/home-hero_img-4.png'
import home_1 from '../../Image/home-1.png'
import home_2 from '../../Image/home-2.png'
import home_3 from '../../Image/home-3.png'
import { Link } from 'react-router-dom';

export default function Home() {


    useEffect(() => {
        // const playActionGroup = (actionGroup, delay) => {
        //     setTimeout(() => {
        //         actionGroup.forEach((action) => console.log(`Executing action ${action.id}`));
        //     }, delay);
        // };

        // const playAllActionGroups = (groups) => {
        //     groups.forEach((group, index) => {
        //         const delay = index === 0 ? 0 : 2000;
        //         playActionGroup(group, delay);
        //     });
        // };

        // const secondGroup = [
        //     {
        //         id: 'a-16-n-8',
        //         actionTypeId: 'TRANSFORM_MOVE',
        //         config: { delay: 2000, easing: "ease", duration: 2000, target: { id: "62bc63a42ce68ca82ef4eb1f|242cd61a-51b6-12c2-19d4-d34a967ac640" }, xValue: 7.69, xUnit: "%", yUnit: "PX", zUnit: "PX" },
        //     },
        //     // Add other second group actions
        // ];



        // const providedScript = [
        //     {
        //         id: 'a-16',
        //         title: 'hero-carousel',
        //         actionItemGroups: [
        //             // ... (Copy your provided script here)
        //         ],
        //         useFirstGroupAsInitialState: true,
        //         createdOn: 1657237662717,
        //     },
        // ];

        // playAllActionGroups([secondGroup]);

        // providedScript.forEach((script) => {
        //     script.actionItemGroups.forEach((group) => {
        //         const delay = 2000; // Adjust delay as needed
        //         playActionGroup(group.actionItems, delay);
        //     });
        // });
    }, []); // Empty dependency array to run the effect only once on component mount

    const sliders = [slider_1, slider_2, slider_3, slider_4, slider_1, slider_2, slider_3, slider_4]




    return (
        <div className="container">
            <div className="section_hero">
                <div className="all-cards">
                    {sliders.map((slider, index) => (
                        <div key={`slider-${index}`} className="cards-move">
                            <div className="hero_card">
                                <img src={slider} loading="lazy" className='hero_card-img' alt={`Slider ${index + 1}`} />
                            </div>
                        </div>
                    ))}
                    <div className="hero_card-mask"></div>
                </div>
                {/* <div className='all-cards'>
                    <Swiper
                        effect={'coverflow'}
                        grabCursor={true}
                        centeredSlides={true}
                        slidesPerView={'auto'}
                        autoplay={{
                            delay: 3000000,
                            disableOnInteraction: false,
                        }}
                        coverflowEffect={{
                            rotate: 50,
                            stretch: 0,
                            depth: 1000,
                            modifier: 1,
                            scale: 1,
                            slideShadows: true,
                        }}
                        pagination={false}
                        modules={[Autoplay, EffectCoverflow, Pagination]}
                        className="mySwiper"
                    >
                        <SwiperSlide>
                            <div className="hero_card !w-auto">
                                <img src={slider_1} className='hero_card-img' />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="hero_card !w-auto">
                                <img src={slider_2} className='hero_card-img' />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="hero_card !w-auto">
                                <img src={slider_3} className='hero_card-img' />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="hero_card !w-auto">
                                <img src={slider_4} className='hero_card-img' />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="hero_card !w-auto">
                                <img src={slider_1} className='hero_card-img' />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="hero_card !w-auto">
                                <img src={slider_2} className='hero_card-img' />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="hero_card !w-auto">
                                <img src={slider_3} className='hero_card-img' />
                            </div>
                        </SwiperSlide>
                        <SwiperSlide>
                            <div className="hero_card !w-auto">
                                <img src={slider_4} className='hero_card-img' />
                            </div>
                        </SwiperSlide>

                    </Swiper>
                </div> */}
                <div className="hero_card-mask"></div>
                <div className="hero_column-left !w-full !items-center !pl-0 !justify-center">
                    <h1 className="heading-xl font-[Lora] text-center">
                        Unlocking
                        <em className="is--white-hero-span"> Value in Real Estate Investment</em>
                    </h1>
                    <div className="container_hero-p text-center">
                        <p className="body-large home-text">
                            Welcome to Capital Intrinsic Real Estate Investment
                        </p>
                    </div>
                    <Link to="Invest_With_Us">
                        <div className="button bg-[#fa6e68] !sm:px-[30px] !px-[20px] !sm:py-[0.95em] !py-[0.5em] group hover:bg-transparent border border-[#fa6e68] rounded-lg  transition-all duration-500 ">
                            <div className="button_text-before is--navy group-hover:text-white transition-all duration-500">Invest with us</div>
                        </div>
                    </Link>
                </div>
                <img
                    src="https://uploads-ssl.webflow.com/62bc63a42ce68cd398f4eb1d/62cd8a6fb0da1d5b303c8e3a_hero_mark-top.svg"
                    loading="eager"
                    alt=""
                    className="hero_mark-bottom"
                />
            </div>
        </div>

    )
}
